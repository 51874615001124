.popular-domains-chart {
    text-align: left;
    border: 1px solid #505359;
    margin-top: 20px;
    padding: 20px;
}

.popular-domains-chart tr td {
    padding: 5px;
    border: 1px solid #505359;
}

.rank-increase {
    color: limegreen;
}

.rank-decrease {
    color: red;
}